import React from "react";

export default function Home() {
  const openDiv = (id) => {
    document.getElementById("overlay" + id).style.display = "block";
  };
  const off = (id) => {
    document.getElementById("overlay" + id).style.display = "none";
  };

  const sendMail = () => {
    const subject = document.getElementById("subject").value;
    const from = document.getElementById("email").value;
    const name = document.getElementById("name").value;
    const body =
      "Hi, I am " +
      name +
      " . My email is " +
      from +
      ". I need following details - " +
      document.getElementById("message").value;
    fetch("https://datasumup.azurewebsites.net/api/mail", {
      method: "PUT",
      mode: "cors",
      body: JSON.stringify({
        from,
        subject,
        body,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .then((response) => {
        if (response === "true") {
          alert("email sent successfully");
        }
      });
  };

  return (
    <React.Fragment>
      <div
        id="topbar"
        className="d-none d-lg-flex align-items-center fixed-top"
      >
        <div className="container d-flex">
          <div className="social-links">
            <a href="#" className="twitter">
              <i className="bx bxl-twitter"></i>
            </a>
            <a href="#" className="facebook">
              <i className="bx bxl-facebook"></i>
            </a>
            <a href="#" className="instagram">
              <i className="bx bxl-instagram"></i>
            </a>
            <a href="#" className="skype">
              <i className="bx bxl-skype"></i>
            </a>
            <a
              href="https://www.youtube.com/channel/UCHVBPl2CEb2r82jDD1Fg-XQ"
              className="youtube"
              style={{ backgroundColor: "#1c8e78" }}
            >
              <i className="bx bxl-youtube"></i>
            </a>
            <a
              href="https://www.linkedin.com/company/datasumup/?viewAsMember=true"
              className="linkedin"
            >
              <i className="bx bxl-linkedin"></i>
            </a>
          </div>
        </div>
      </div>
      <header id="header" className="fixed-top">
        <div className="container d-flex align-items-center">
          {/* @*
          <h1 className="logo mr-auto">
            <a href="index.html">
              {" "}
              <span>.</span>
            </a>
          </h1>
          *@ */}
          <a href="index.html" className="logo mr-auto">
            <img src="/Content/images/1stLogo.png" />
          </a>
          <nav className="nav-menu d-none d-lg-block">
            <ul>
              <li className="active">
                <a href="/">Home</a>
              </li>
              <li>
                <a href="#about">About</a>
              </li>
              <li>
                <a href="#services">Services</a>
              </li>
              {/* <li>
                <a href="#portfolio">Blog</a>
              </li> */}
              <li>
                <a href="#contact">Contact</a>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <section id="hero" className="d-flex align-items-center">
        <div className="container" data-aos="zoom-out" data-aos-delay="100">
          <h1>
            Welcome to <span style={{ color: "#1c8e78" }}>DATASUMUP</span>
          </h1>
          <h2>
            Complete solution provider for data management and data analytics.
          </h2>
          <div className="d-flex" style={{ color: "#1c8e78" }}>
            <a href="#about" className="btn-get-started scrollto">
              Get Started
            </a>
            {/* @*
            <a
              href="https://www.youtube.com/watch?v=jDDaplaOz7Q"
              className="venobox btn-watch-video"
              data-vbtype="video"
              data-autoplay="true"
            >
              {" "}
              Watch Video
            </a>
            *@ */}
          </div>
        </div>
      </section>
      <main id="main">
        <section id="about" className="about section-bg">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h3>
                Find Out More <span style={{ color: "#1c8e78" }}>About Us</span>
              </h3>
              <p style={{ fontSize: "15px", width: "100%" }}>
                We help organizations extract value from their data through
                strategic assessments, elegant design, and advanced data
                engineering and implementation. We specialize in big data,
                modern data architecture, cloud migration, enterprise data
                management, business intelligence, data visualization, advanced
                analytics and machine learning.
              </p>
            </div>

            <div className="row">
              <div
                className="col-lg-6"
                data-aos="zoom-out"
                data-aos-delay="100"
              >
                <img
                  src="/Content/assets/img/about.jpg"
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div
                className="col-lg-6 pt-4 pt-lg-0 content d-flex flex-column justify-content-center"
                data-aos="fade-up"
                data-aos-delay="100"
              >
                <h3>Your data, your terms.</h3>
                <p style={{ fontSize: "15px", width: "100%" }}>
                  Your project has unique needs. We’re here to work with you on
                  your terms. Whether it be an existing tech stack that you
                  would like to stay with, a tight budget, or a time frame that
                  you need to adhere to, our team will work with you to get it
                  done right.
                </p>
                <ul>
                  <li>
                    <i className="bx bx-store-alt"></i>
                    <div>
                      <h5>Data-based business insights</h5>
                      <p>
                        Help to drive businesses more efficiently and
                        effectively using various tools like Power BI, Tableau ,
                        etc.
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="bx bx-images"></i>
                    <div>
                      <h5>Migration of existing data</h5>
                      <p>
                        From old systems to modern database systems for creating
                        meaningful insights from existing historical data set
                      </p>
                    </div>
                  </li>
                  <li>
                    <i className="bx bx-store-alt"></i>
                    <div>
                      <h5>Data transformation</h5>
                      <p>
                        Solution for meaningful data processing by effectively
                        doing data transformation work
                      </p>
                    </div>
                  </li>{" "}
                  +
                  <li>
                    <i className="bx bx-images"></i>
                    <div>
                      <h5>Complete data management</h5>
                      <p>To RDBMS based systems from old legacy systems</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        <section id="counts" className="counts">
          <div className="container" data-aos="fade-up">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="count-box">
                  <i className="icofont-simple-smile"></i>
                  <span data-toggle="counter-up">232</span>
                  <p>Happy Clients</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-md-0">
                <div className="count-box">
                  <i className="icofont-document-folder"></i>
                  <span data-toggle="counter-up">521</span>
                  <p>Projects</p>
                </div>
                +
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="icofont-live-support"></i>
                  <span data-toggle="counter-up">1,463</span>
                  <p>Hours Of Support</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6 mt-5 mt-lg-0">
                <div className="count-box">
                  <i className="icofont-users-alt-5"></i>
                  <span data-toggle="counter-up">15</span>
                  <p>Hard Workers</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section id="clients" className="clients section-bg">
          <div className="container" data-aos="zoom-in">
            <div className="row">
              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-1.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-2.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-3.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-4.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-5.png"
                  className="img-fluid"
                  alt=""
                />
              </div>

              <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
                <img
                  src="assets/img/clients/client-6.png"
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </section> */}

        <section id="services" className="services">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h3>
                Check our <span style={{ color: "#1c8e78" }}>Services</span>
              </h3>
            </div>

            <div className="row">
              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch"
                data-aos="zoom-in"
                data-aos-delay="100"
                onClick={() => openDiv(1)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src="/Content/images/icn-business-intelligence.webp"
                    />
                  </div>
                  <h4>DATA Analytics and Consulting</h4>
                  <div align="left">
                    <h6>
                      <ul>
                        <li>
                          <p>Descriptive analytics</p>
                        </li>
                        <li>
                          <p>Diagnostic analytics</p>
                        </li>
                        <li>
                          <p>Predictive analytics</p>
                        </li>
                        <li>
                          <p>Prescriptive analytics</p>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
                data-aos="zoom-in"
                data-aos-delay="200"
                onClick={() => openDiv(2)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src="/Content/images/icn-data-visualization.webp"
                    />
                  </div>
                  <h4>Business Intelligence & Visualization consulting</h4>
                  <div align="left">
                    <h6>
                      <ul>
                        <li>
                          <p>BI Consulting and Implementation</p>
                        </li>
                        <li>
                          <p>Power BI & Tableau Consulting</p>
                        </li>
                        <li>
                          <p>Azure PaaS Consulting</p>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="300"
                onClick={() => openDiv(3)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src="/Content/images/icn-cloud-migration.webp"
                    />
                  </div>
                  <h4>DATA Support & Maintenance</h4>
                  <div align="left">
                    <h6>
                      <ul>
                        <li>
                          <p>Production support and maintenance services </p>
                        </li>
                        <li>
                          <p>Architecture and advisory services</p>
                        </li>
                        <li>
                          <p>Support for Critical production failures</p>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>
              </div>

              <div
                className="col-lg-4 col-md-6 d-flex align-items-stretch mt-4 mt-lg-0"
                data-aos="zoom-in"
                data-aos-delay="300"
                onClick={() => openDiv(4)}
              >
                <div className="icon-box">
                  <div className="icon">
                    <img
                      style={{ height: "50px", width: "50px" }}
                      src="/Content/images/icn-enterprise-data-management.webp"
                    />
                  </div>
                  <h4>Database consulting service</h4>
                  <div align="left">
                    <h6>
                      <ul>
                        <li>
                          <p>Database infrastructure setup consulting</p>
                        </li>
                        <li>
                          <p>Data migration service</p>
                        </li>
                        <li>
                          <p>Database design</p>
                        </li>
                        <li>
                          <p>Data transformation service</p>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="faq" className="faq section-bg" hidden="hidden">
          <div className="container" data-aos="fade-up">
            <div className="section-title">
              <h2 style={{ color: "#1c8e78" }}>Consulting</h2>
              <h3>
                We provide database related consulting service for our customer
                also
              </h3>
            </div>

            <ul className="faq-list" data-aos="fade-up" data-aos-delay="100">
              <li>
                <a data-toggle="collapse" className="" href="#faq1">
                  Our specialist consultants can guide customers on
                  <i className="icofont-simple-up"></i>
                </a>
                <div
                  id="faq1"
                  className="collapse show"
                  data-parent=".faq-list"
                >
                  <p>
                    Data migration tools and techniques that is most suited for
                    the business
                  </p>
                  <p>
                    Database design and database (Sql Server,Oracle,Cloud
                    hosting) to use based upon business size and volume
                  </p>
                  <p>
                    Data Transformation tools and techniques based upon
                    requirement
                  </p>
                </div>
              </li>

              <li>
                <a data-toggle="collapse" href="#faq2" className="collapsed">
                  We do <i className="icofont-simple-up"></i>
                </a>
                <div id="faq2" className="collapse" data-parent=".faq-list">
                  <p>Data Migration</p>
                  <p>Data Mining</p>
                  <p>Data Transformation</p>
                  <p>Data based insights / visualization</p>
                </div>
              </li>
            </ul>
            <div className="section-title">
              <h2>
                specializes in Developing Reporting Systems and Datawarehouse of
                various types based upon customer need and requirement.
              </h2>
            </div>
          </div>
        </section>

        <section id="contact" className="contact">
          <div className="container" data-aos="fade-up">
            <div className="section-title"></div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6">
                <div className="info-box mb-4">
                  <i className="bx bx-map"></i>
                  <h3>Our Address</h3>
                  <p>B1/308, Kalyani, Nadia</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-envelope"></i>
                  <h3>Email Us</h3>
                  <p>info@datasumup.com</p>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className="info-box  mb-4">
                  <i className="bx bx-phone-call"></i>
                  <h3>Call Us</h3>
                  <p>+91 8777591549</p>
                </div>
              </div>
            </div>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-6 ">
                <iframe
                  className="mb-4 mb-lg-0"
                  src="https://maps.google.com/maps?width=600&amp;height=2038&amp;hl=en&amp;q=DATASUMUP TECHNOLOGY SOLUTION PVT LTD&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                  frameBorder="0"
                  style={{ border: 0, width: "100%", height: "384px" }}
                  allowFullScreen
                ></iframe>
              </div>

              <div className="col-lg-6">
                <form role="form" className="php-email-form">
                  <div className="form-row">
                    <div className="col form-group">
                      <input
                        type="text"
                        name="name"
                        className="form-control"
                        id="name"
                        placeholder="Your Name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div className="validate"></div>
                    </div>
                    <div className="col form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder="Your Email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div className="validate"></div>
                    </div>
                  </div>
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control"
                      name="subject"
                      id="subject"
                      placeholder="Subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <div className="validate"></div>
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      rows="5"
                      data-rule="required"
                      data-msg="Please write something for us"
                      placeholder="Message"
                    ></textarea>
                    <div className="validate"></div>
                  </div>
                  <div className="mb-3">
                    <div className="loading">Loading</div>
                    <div className="error-message"></div>
                    <div className="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div className="text-center">
                    <input
                      type="button"
                      style={{
                        backgroundColor: "#1c8e78",
                        border: 0,
                        padding: "10px 30px",
                        color: "#fff",
                        transition: "0.4s",
                        borderRadius: "4px",
                      }}
                      onClick={sendMail}
                      value="Send Message"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6 footer-contact">
                <h3>DATASUMUP</h3>
                <p>
                  B1/308, Kalyani, Nadia <br />
                  India <br />
                  <br />
                  <strong>Phone:</strong> +91 8777591549
                  <br />
                  <strong>Email:</strong> info@datasumup.com
                  <br />
                </p>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="/">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">DATA INGESTION</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">DATA STORAGE</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">DATA PREPARATION</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">DATA MODELING & ANALYSIS</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#services">DATA VISUALIZATION</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-3 col-md-6 footer-links">
                <h4>Our Social Networks</h4>
                <div className="social-links mt-3">
                  <a
                    href="#"
                    className="twitter"
                    style={{ backgroundColor: "#1c8e78" }}
                  >
                    <i className="bx bxl-twitter"></i>
                  </a>
                  <a
                    href="#"
                    className="facebook"
                    style={{ backgroundColor: "#1c8e78" }}
                  >
                    <i className="bx bxl-facebook"></i>
                  </a>
                  <a
                    href="#"
                    className="instagram"
                    style={{ backgroundColor: "#1c8e78" }}
                  >
                    <i className="bx bxl-instagram"></i>
                  </a>
                  <a
                    href="https://www.youtube.com/channel/UCHVBPl2CEb2r82jDD1Fg-XQ"
                    className="youtube"
                    style={{ backgroundColor: "#1c8e78" }}
                  >
                    <i className="bx bxl-youtube"></i>
                  </a>
                  <a
                    href="https://www.linkedin.com/company/datasumup/?viewAsMember=true"
                    className="linkedin"
                    style={{ backgroundColor: "#1c8e78" }}
                  >
                    <i className="bx bxl-linkedin"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container py-4">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>DATASUMUP Technology Solutions Pvt Limited</span>
            </strong>
            . All Rights Reserved
          </div>
        </div>
      </footer>

      <div id="preloader"></div>
      <a href="#" className="back-to-top">
        <i
          className="icofont-simple-up"
          style={{ backgroundColor: "#1c8e78" }}
        ></i>
      </a>

      <div id="overlay1" className="overlay" onClick={() => off(1)}>
        <div
          id="loginScreen"
          className="d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="text-center">
                <p
                  className="responsivetext"
                  style={{
                    color: "#1c8e78",
                    fontSize: "3em",
                    fontWeight: "bold",
                  }}
                >
                  DATA Analytics and Consulting
                </p>
              </div>
            </div>
            <div className="row">
              <div className="text-center">
                <img
                  src="/Content/images/icn-business-intelligence.webp"
                  className="img-fluid responsiveimage"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  We help leaders looking to gain competitive advantage for
                  their companies and to improve their process operational
                  efficiencies innovate by and through their data. Our data and
                  analytics consulting services are design-led and
                  framework-based, which reduce time to delivery and improves
                  accuracy and impact of the outcomes. Unlike technology led
                  solutions, our services combine both strategic advisory and
                  technology to align business needs with proven and emerging
                  solutions that drive targeted business outcomes.
                </p>
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Our client engagements start with an initial assessment and
                  blueprint. After that we deliver a data and analytics strategy
                  and delivery roadmap. DATASUMUP’s unrivalled data management
                  consultants provide end-to-end implementation of our
                  assessments when you need the right talent to get the project
                  done right, on time, and on budget. We ensure post-engagement
                  support to make sure the hand-off to your team is seamless.
                </p>
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Our consultants, unrivalled in their talent, have deep domain
                  expertise across the full data stack. They have complete 360
                  degree view and knowledge around all data analytics pillars.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="overlay2" className="overlay" onClick={() => off(2)}>
        <div
          id="loginScreen"
          className="d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="text-center">
                <p
                  className="responsivetext"
                  style={{
                    color: "#1c8e78",
                    fontSize: "3em",
                    fontWeight: "bold",
                  }}
                >
                  Business Intelligence & Visualization consulting
                </p>
              </div>
            </div>
            <div className="row">
              <div className="text-center">
                <img
                  src="/Content/images/icn-data-visualization.webp"
                  className="img-fluid responsiveimage"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  BI Consulting and Implementation
                </p>
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Translate your data into intelligent insights easily to
                  facilitate decision-making Power BI & Tableau Consulting
                </p>
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Develop your best Power BI Consulting and Implementation
                  Strategy We all know that a picture is worth a thousand words.
                  So why are you still flipping through pages of numbers?
                  Business Intelligence will surface the right information to
                  accelerate decision-making. Well-designed visualization
                  solutions enable users to explore “beyond the dashboard”.
                  Centric will connect business process with tools, creating a
                  powerful information-rich experience. Our expertise in Power
                  BI and Tableau will help you deliver actionable
                  enterprise-level insights across your organization.
                </p>
                <p
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Azure Pass Development Azure Consulting Azure DevOps & CI/CD
                  implementation
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="overlay3" className="overlay" onClick={() => off(3)}>
        <div
          id="loginScreen"
          className="d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="text-center">
                <p
                  className="responsivetext"
                  style={{
                    color: "#1c8e78",
                    fontSize: "3em",
                    fontWeight: "bold",
                  }}
                >
                  DATA Support & Maintenance
                </p>
              </div>
            </div>
            <div className="row">
              <div className="text-center">
                <img
                  src="/Content/images/icn-cloud-migration.webp"
                  className="img-fluid responsiveimage"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p
                  className="responsive-text"
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  Is your IT team bandwidth-constrained? You’re not alone. Many
                  organizations are challenged to allocate time and skills to
                  support a data solution. We can shoulder the burden. We offer
                  support and maintenance services specifically for Data &
                  Analytics solutions. This includes SLAs, fractional support,
                  blended offshore/onshore teams, as well as architecture and
                  advisory services. We’ll bring the right talent, customized to
                  your unique requirements
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="overlay4" className="overlay" onClick={() => off(4)}>
        <div
          id="loginScreen"
          className="d-flex align-items-stretch"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="container-fluid">
            <div className="row">
              <div className="text-center">
                <p
                  className="responsivetext"
                  style={{
                    color: "#1c8e78",
                    fontSize: "3em",
                    fontWeight: "bold",
                  }}
                >
                  Database consulting service
                </p>
              </div>
            </div>
            <div className="row">
              <div className="text-center">
                <img
                  src="/Content/images/icn-enterprise-data-management.webp"
                  className="img-fluid responsiveimage"
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <ul
                  className="responsive-text"
                  style={{
                    color: "#1c8e78",
                    fontSize: "1.3em",
                    fontWeight: "bold",
                  }}
                >
                  <li>Database infrastructure setup consulting</li>
                  <li>Data migration service</li>
                  <li>Database design</li>
                  <li>Data transformation service</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
