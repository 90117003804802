export const firebaseConfig = {
  apiKey: "AIzaSyDKUczMZ8eIkW0OaYhBBKMDZv2llgFrSXM",
  authDomain: "datasumup-leetbyte.firebaseapp.com",
  projectId: "datasumup-leetbyte",
  storageBucket: "datasumup-leetbyte.appspot.com",
  messagingSenderId: "308220210249",
  appId: "1:308220210249:web:bf4f3975c441660260a398",
  measurementId: "G-W11L5MH6K4",
};

